import React from "react";
import { Layout } from "antd";
import {
  Header,
  Banner,
  AboutUs,
  OurServices,
  OurEvents,
  OurClients,
  ContactUs,
} from "./modules";
import "./App.css";

const { Content } = Layout;

function App() {
  return (
    <Layout className="layout">
      <Header />
      <Content className="site-layout-content">
        <Banner />
        <AboutUs />
        <OurServices />
        <OurEvents />
        <OurClients />
      </Content>
      <ContactUs />
    </Layout>
  );
}

export default App;
