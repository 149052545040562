import React from "react";
import "./style.css";

import planIcon from "../../assets/service-detailed-planning.png";
import creativeIcon from "../../assets/service-creative-solutions.png";
import customerExpIcon from "../../assets/service-custom-exp.png";

function OurServices() {
  return (
    <div id="our-services">
      <div className="service-bg service-bg-1"></div>
      <div className="service-bg service-bg-2"></div>
      <div className="service-bg service-bg-3"></div>
      <div className="services-filter"></div>
      <div className="service-items">
        <div className="service-item service-item-1">
          <img alt="" src={planIcon} />
          <div className="title">Detailed Planning</div>
          <p>Flexibility</p>
          <p>Eye for details</p>
          <p>Budgeting</p>
        </div>
        <div className="service-item service-item-2">
          <img alt="" src={creativeIcon} />
          <div className="title">Creative Solutions</div>
          <p>Working with trusted partners </p>
          <p>Exploring technology </p>
          <p>Evolving ideas</p>
        </div>
        <div className="service-item service-item-3">
          <img alt="" src={customerExpIcon} />
          <div className="title">Custom Experience</div>
          <p>Taking content to create end to end customer experience</p>
          <p>
            Onsite coordination to ensure plans are executed and followed
            through
          </p>
          <p>Bringing your plans to life</p>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
