import React from "react";
import { Layout } from "antd";
import { ContactForm } from "../../components";

import "./style.css";

import logo from "../../assets/footer-mp-logo.png";
import instaLogo from "../../assets/insta.png";
import emailLogo from "../../assets/email.png";

const { Footer } = Layout;

function ContactUs() {
  return (
    <Footer id="footer">
      <div className="footer-content info">
        <div id="footer-about-us">
          <img id="footer-logo" src={logo} alt="THE MASTERPLAN" />
          <p>
            We do not simply carry out instructions. We challenge, we innovate
            and we organise events like no one else does. Just ask our clients!
            They are happy to refer us to their friends and associates and most
            of our clients have learnt about us through word-of-mouth. To us,
            that is the best form of affirmation.
            <br />
            <span className="enquiries-remark">
              If you have any enquiries please contact us
            </span>
          </p>
        </div>
        <div id="footer-contact">
          <div className="footer-title">Contact</div>
          <p>
            45 Jalan Pemimpin, Foo Wah Industrial Building, #07-00B, Singapore
            577197
            <br />
            <br />
            <a href="mailto:events@themasterplan.com.sg">
              events@themasterplan.com.sg
            </a>
          </p>
          <div className="contact-logos-container">
            <a
              href="https://www.instagram.com/themasterplansg/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={instaLogo}
                alt="Follow on Instagram"
                width="22"
                style={{ marginRight: "22px" }}
              />
            </a>
            <a href="mailto: events@themasterplan.com.sg">
              <img src={emailLogo} alt="Email Us" width="26" />
            </a>
          </div>
        </div>
        <div id="footer-quick-links">
          <div className="footer-title">Quick Links</div>
          <ul>
            <li>
              <a href="#about-us">About us</a>
            </li>
            <li>
              <a href="#our-services">What can we do for you today?</a>
            </li>
            <li>
              <a href="#our-events">Our events</a>
            </li>
            <li>
              <a href="#our-clients">Testimonials</a>
            </li>
            <li>
              <a href="#banner">Home</a>
            </li>
          </ul>
        </div>
        <div id="footer-contact-form">
          <ContactForm />
        </div>
      </div>
      <div className="footer-divider"></div>
      <div className="footer-content copyright">
        <div>Copyright © 2019 THE MASTERPLAN LLP. All Rights Reserved.</div>
      </div>
    </Footer>
  );
}

export default ContactUs;
