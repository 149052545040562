import React from "react";
import { Button } from "antd";
import "./style.css";

import aboutUsImage from "../../assets/about-us.png";

function AboutUs() {
  return (
    <div id="about-us">
      <div className="section-title">ABOUT US</div>
      <div className="about-us-content">
        <div className="about-us-left">
          <h1>Hello. Welcome to The MasterPlan.</h1>
          <p>
            Since 2010, we have been planning and executing impactful
            business-to-business events, including the likes of conferences,
            seminars, media launches, product showcases, C-Suite meetings, and
            virtual events.
          </p>
          <p>
            Truth be told, it took us 10 years to get around to putting up a
            website. Establishing a digital presence never quite ranked at the
            top of our To-Do list as we’ve grown a loyal client base over time
            purely via word-of-mouth. We like to think that this says something
            about the quality of our events and services. A satisfied customer
            is, after all, the best source of advertisement.
          </p>
          <p>
            The majority of our clients reside in the technology space. With a
            shared respect for collaboration, innovation, and agility, our tech
            clients enjoy working with us because we function almost as a
            seamless plug-and-play solution each time they need to stage an
            event. Our team is highly-responsive, flexible, and detail-oriented
            – key qualities that our clients love. After a decade of dry runs,
            set-ups and teardowns, we have also built strong relationships with
            a reliable network of vendors.
          </p>
          <p>Once we receive your brief, we hit the ground running.</p>
          <a href="#our-events">
            <Button>WHAT CAN WE DO FOR YOU TODAY?</Button>
          </a>
        </div>
        <div className="about-us-right">
          <img src={aboutUsImage} alt="" />
          <div className="about-us-rec"></div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
