import React from "react";
import { EventItem } from "../../components";
import "./style.css";
import eventsConfig from "./eventsConfig";

const renderEventItems = (events) =>
  events.map(({ title, imageUrl }, index) => (
    <EventItem key={index} title={title} imageUrl={imageUrl} />
  ));

function OurEvents() {
  return (
    <div id="our-events">
      <div className="section-title">OUR EVENTS</div>
      <div className="our-events-content">
        <h1>A sampling of what we can do for you</h1>
        <p className="content-lg">
          Here’s a look at some events we have staged for clients. As all our
          events and services are customised to satisfy specific briefs,
        </p>
        <p className="content-lg">
          we prefer to connect with you and have a better understanding of what
          you need. <a href="#contact-form">Let's chat</a>
        </p>

        <p className="content-sm">
          Here’s a look at some events we have staged for clients. As all our
          events and services are customised to satisfy specific briefs, we
          prefer to connect with you and have a better understanding of what you
          need. <a href="#contact-form">Let's chat</a>
        </p>

        <div className="event-items-container">
          {renderEventItems(eventsConfig)}
        </div>
      </div>
    </div>
  );
}

export default OurEvents;
