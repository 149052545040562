import React, { useState } from "react";
import { Layout } from "antd";
import "./style.css";

import { Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import logo from "../../assets/mp-logo.png";
import instaLogo from "../../assets/insta.png";
import emailIcon from "../../assets/email.png";

const { Header } = Layout;

function AppHeader() {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleMenu = () => {
    setShowDrawer((prevValue) => !prevValue);
  };

  const onClose = () => {
    setShowDrawer(false);
  };

  window.addEventListener("scroll", () => {
    let header = document.querySelector("header");
    header.classList.toggle("sticky", window.scrollY > 100);

    let aboutUs = document.getElementById("about-us");
    let ourServices = document.getElementById("our-services");
    let ourEvents = document.getElementById("our-events");
    let footer = document.getElementById("footer");

    const aboutUsOffsetY = aboutUs && aboutUs.offsetTop;
    const ourServicesOffsetY = ourServices && ourServices.offsetTop;
    const ourEventsOffsetY = ourEvents && ourEvents.offsetTop;
    const footerOffsetY = footer && footer.offsetTop;

    let homeAnchor = document.getElementById("nav-home");
    let aboutUsAnchor = document.getElementById("nav-about-us");
    let ourServicesAnchor = document.getElementById("nav-our-services");
    let ourEventsAnchor = document.getElementById("nav-our-clients");
    let footerAnchor = document.getElementById("nav-footer");

    const currentScrollY = window.scrollY;
    const halfWindowHeight = Math.floor(window.innerHeight / 2);

    const scrollPosition = currentScrollY + halfWindowHeight;

    footerAnchor.classList.toggle("active", footerOffsetY < scrollPosition);

    ourEventsAnchor.classList.toggle(
      "active",
      ourEventsOffsetY < scrollPosition && scrollPosition < footerOffsetY
    );

    ourServicesAnchor.classList.toggle(
      "active",
      ourServicesOffsetY < scrollPosition && scrollPosition < ourEventsOffsetY
    );

    aboutUsAnchor.classList.toggle(
      "active",
      aboutUsOffsetY < scrollPosition && scrollPosition < ourServicesOffsetY
    );

    homeAnchor.classList.toggle("active", scrollPosition < aboutUsOffsetY);
  });

  return (
    <Header>
      <div className="hamburger-container">
        <Button onClick={toggleMenu}>
          <MenuOutlined />
        </Button>
      </div>

      <Drawer
        placement="top"
        closable={false}
        onClose={onClose}
        visible={showDrawer}
      >
        <ul>
          <li>
            <a href="#banner" onClick={onClose}>
              Home
            </a>
          </li>
          <li>
            <a href="#about-us" onClick={onClose}>
              About Us
            </a>
          </li>
          <li>
            <a href="#our-services" onClick={onClose}>
              What Can We Do For You Today?
            </a>
          </li>
          <li>
            <a href="#our-clients" onClick={onClose}>
              Testimonials
            </a>
          </li>
          <li>
            <a href="#footer" onClick={onClose}>
              Let’s Connect
            </a>
          </li>
        </ul>
      </Drawer>

      <div className="logo-container">
        <a href="#banner" onClick={onClose}>
          <img src={logo} alt="The MasterPlan" />
        </a>
      </div>

      <ul>
        <li id="nav-home">
          <a href="#banner">Home</a>
        </li>
        <li id="nav-about-us">
          <a href="#about-us">About Us</a>
        </li>
        <li id="nav-our-services">
          <a href="#our-services">What Can We Do For You Today?</a>
        </li>
        <li id="nav-our-clients">
          <a href="#our-clients">Testimonials</a>
        </li>
        <li id="nav-footer">
          <a href="#footer">Let’s Connect</a>
        </li>
      </ul>
      <div className="social-media-logos-container">
        <a
          href="https://www.instagram.com/themasterplansg/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img className="insta-logo" src={instaLogo} alt="" height="22" />
        </a>
        <a href="mailto: events@themasterplan.com.sg">
          <img className="email-logo" src={emailIcon} alt="" height="20" />
        </a>
      </div>
    </Header>
  );
}

export default AppHeader;
