import React from "react";
import { Carousel } from "antd";
import "./style.css";

function Banner() {
  return (
    <div id="banner">
      <Carousel dotPosition="left" autoplay>
        <div className="banner-item banner-item-1">
          <div className="title">The blueprint for your events</div>
          <div className="primary-btn">
            <a href="#footer">CONTACT US</a>
          </div>
        </div>
        <div className="banner-item banner-item-2">
          <div className="title">The blueprint for your events</div>
          <div className="primary-btn">
            <a href="#footer">CONTACT US</a>
          </div>
        </div>
        <div className="banner-item banner-item-3">
          <div className="title">The blueprint for your events</div>
          <div className="primary-btn">
            <a href="#footer">CONTACT US</a>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default Banner;
