import img1 from "../../assets/event-1.jpg";
import img2 from "../../assets/event-2.jpg";
import img3 from "../../assets/event-3.jpg";
import img4 from "../../assets/event-4.jpg";
import img5 from "../../assets/event-5.jpg";
import img6 from "../../assets/event-6.jpg";

const eventsConfig = [
  {
    title: "Stripe Launch Malaysia 2019",
    imageUrl: img4,
  },
  {
    title: "UberFLASH Launch 2018",
    imageUrl: img1,
  },
  {
    title: "CMO SEA Video Summit 2019",
    imageUrl: img2,
  },
  {
    title: "LinkedIn TECH Connect 2018",
    imageUrl: img3,
  },
  {
    title: "Create with Google Cannes Festival 2019",
    imageUrl: img5,
  },
  {
    title: "Houzz 1st Anniversary 2017",
    imageUrl: img6,
  },
];

export default eventsConfig;
