import React from "react";
import { Carousel } from "antd";

import "./style.css";

import googleLogo from "../../assets/client-google.png";
import grabLogo from "../../assets/client-grab.png";
import linkedinLogo from "../../assets/client-linkedin.png";
import twilioLogo from "../../assets/client-twilio.png";
import salesforceLogo from "../../assets/client-salesforce.png";
import cloudflareLogo from "../../assets/client-cloudflare.png";
import stripLogo from "../../assets/client-stripe.png";
import intertankoLogo from "../../assets/client-intertanko.jpg";
import quoteIcon from "../../assets/clients-quote.png";
import profileTimWilkins from "../../assets/profile-tim-wilkins.png";
import profileJon from "../../assets/profile-jon-stona.png";
import profileLars from "../../assets/profile-lars-anthonisen.png";
import profileMichelle from "../../assets/profile-michelle-lim.png";
import profileGoogleHk from "../../assets/profile-google-hk.png";

import profileAppAnnie from "../../assets/client-app-annie.png";
import profileAppier from "../../assets/client-appier.png";
import profileAsus from "../../assets/client-asus.jpg";
import profileCarousell from "../../assets/client-carousell.png";
import profileFacebook from "../../assets/client-facebook.png";
import profileHouzz from "../../assets/client-houzz.png";
import profileHubspot from "../../assets/client-hubspot.png";
import profileIProspect from "../../assets/client-iprospect.png";
import profileJitta from "../../assets/client-jitta.png";
import profileUber from "../../assets/client-uber.png";

const config = [
  {
    name: "Tim Wilkins",
    title: "Environment Director, Regional Manager Asia Pacific",
    company: "Intertanko",
    content:
      "INTERTANKO has used TMP for two major international events in Singapore and can’t recommend them highly enough. TMP were simply tip-top in every respect. The TMP team is a real pleasure to work with. They’re both extremely hard working and professional in every respect while at the same time fun and pleasant to have around your event",
    imageSrc: profileTimWilkins,
  },
  {
    name: "Jon Stona",
    title: "APAC Marketing",
    company: "Stripe",
    content:
      "TMP has been a great partner and one that I can rely on to deliver high quality work and great service. The TMP team is an absolute joy to work with!",
    imageSrc: profileJon,
  },
  {
    name: "Michelle Lim",
    title: "Marketing | Marketing Cloud, Asia",
    company: "Salesforce",
    content:
      "The MasterPlan event agency is a good event company that you should consider partnering to work with. The event staff are professional and they are very easy to work with. I will definitely work with TMP again!",
    imageSrc: profileMichelle,
  },
  {
    name: "Lars Anthonisen",
    title: "Head of Marketing | Singapore, Malaysia & South Asia Frontiers",
    company: "Google",
    content:
      "I’ve been working with The MasterPlan for the past 6 years. The young and dynamic team have been swift in our requests and are very adaptable to change. I would recommend them to anyone who is looking for an events agency with an end to end service!",
    imageSrc: profileLars,
  },
  {
    name: "Ads Marketing team",
    title: "",
    company: "Google Hong Kong",
    content:
      "It's great working with The MasterPlan! We've collaborated on several small and large events in Hong Kong. They are amazing at what they do, they're true innovators in the field who are true strategic partners and experts in execution - able to drive visions at the high level and roll up their sleeves to help you deliver. We always look forward to our next collaboration with The MasterPlan! ",
    imageSrc: profileGoogleHk,
  },
];

const TestimonialItem = ({ name, title, company, content, imageSrc }) => (
  <div>
    <div className="testimonial-item">
      <div className="profile">
        {!!imageSrc && <img src={imageSrc} alt="" />}
      </div>
      <div className="testimonial-item-content">
        <div>
          <p className="testimonial-content">{content}</p>
          <p className="testimonial-name">{name} </p>
          <p className="testimonial-title">{title}</p>
          <p className="testimonial-company">{company}</p>
        </div>
      </div>
    </div>
  </div>
);

const renderTestimonialItem = (config) =>
  config.map(({ name, title, company, content, imageSrc }, index) => (
    <TestimonialItem
      key={index}
      name={name}
      title={title}
      company={company}
      content={content}
      imageSrc={imageSrc}
    />
  ));

function OurClients() {
  return (
    <div id="our-clients">
      <div className="our-clients-top-container">
        <h1>Don’t take it from us. Hear what our clients have to say</h1>
      </div>
      <div className="our-clients-bottom-container">
        <div className="clients-logo-container-1">
          <img src={googleLogo} alt="Google" id="google-logo" />
          <img src={linkedinLogo} alt="LinkedIn" id="linkedin-logo" />
          <img src={twilioLogo} alt="Twilio" id="twilio-logo" />
          <img src={salesforceLogo} alt="Salesforce" id="salesforce-logo" />
        </div>
        <div className="clients-logo-container-2">
          <img src={cloudflareLogo} alt="Cloudflare" id="cloudflare-logo" />
          <img src={grabLogo} alt="Grab" id="grab-logo" />
          <img src={stripLogo} alt="Strip" id="strip-logo" />
          <img src={intertankoLogo} alt="Intertanko" id="intertanko-logo" />
        </div>
        <div className="clients-logo-container-2">
          <img src={profileAppAnnie} alt="App Annie" id="appannie-logo" />
          <img src={profileAppier} alt="appier" id="appier-logo" />
          <img src={profileAsus} alt="asus" id="asus-logo" />
          <img src={profileCarousell} alt="Carousell" id="carousell-logo" />
        </div>
        <div className="clients-logo-container-2">
          <img src={profileFacebook} alt="Facebook" id="facebook-logo" />
          <img src={profileHouzz} alt="houzz" id="houzz-logo" />
          <img src={profileHubspot} alt="hubspot" id="hubspot-logo" />
          <img src={profileIProspect} alt="iProspect" id="iprospect-logo" />
        </div>
        <div className="clients-logo-container-2 clients-logo-container-3">
          <img src={profileJitta} alt="Jitta" id="jitta-logo" />
          <img src={profileUber} alt="Uber" id="uber-logo" />
        </div>
      </div>
      <div className="testimonials-contianer">
        <div className="testimonials">
          <div className="testimonials-quote">
            <img src={quoteIcon} alt="" />
          </div>
          <div style={{ zIndex: 2 }}>
            <Carousel dotPosition="right" autoplay autoplaySpeed={8000}>
              {renderTestimonialItem(config)}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurClients;
