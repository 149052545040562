import React from "react";
import "./style.css";

const EventItem = ({ title, imageUrl }) => {
  return (
    <div className="event-item" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="event-item-title">{title}</div>
    </div>
  );
};

export default EventItem;
