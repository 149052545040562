import React, { useState } from "react";
import { Input, Button } from "antd";
import "./style.css";

const { TextArea } = Input;

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    message: "",
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div id="contact-form">
      <div className="input-item">
        <Input
          name="name"
          value={formData.name}
          placeholder="Name"
          onChange={onChange}
        />
      </div>
      <div className="input-item">
        <TextArea
          name="message"
          value={formData.message}
          rows={5}
          placeholder="Message"
          onChange={onChange}
        />
      </div>
      <a
        href={`mailto:events@themasterplan.com.sg?subject=Let's Connect&body=Name: ${formData.name}   Message: ${formData.message}`}
      >
        <Button>LET'S CONNECT</Button>
      </a>
    </div>
  );
}

export default ContactForm;
